.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  min-height: 100vh;
  display: block;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: 'Lato', sans-serif;
  background-repeat: repeat;
}

.App-link {
  color: #09d3ac;
}

.App-header h1 {
  margin-top: 0px;
  padding-top: 15px;
}
.imgbutton img {
  cursor: pointer;
  width: 300px;
}
/* Nav */
.nav {
  width: 100%;
  overflow: hidden;
  background-color: #585858;
}
.marginGuide {
  margin-right: 4%;
  margin-left: 4%;
}
.nav .menu button {
  float: left;
  margin-top: 10px;
  margin-bottom: 5px;
  font-size: 25px;
  background-color: #585858;
  border: 0px;
  color: white;
  border-radius: 10px;
  font-family: 'Lato', sans-serif;
  font-weight: bold;
  padding: 7px;
  margin-right: 1%;
  cursor: pointer;
}
.nav .logout button {
  float: right;
  margin-top: 10px;
  margin-bottom: 5px;
  font-size: 25px;
  background-color: #585858;
  border: 0px;
  color: white;
  border-radius: 10px;
  font-family: 'Lato', sans-serif;
  font-weight: bold;
  padding: 7px;
  margin-right: 1%;
  cursor: pointer;
}
.menu a {
  text-decoration: none;
  color: white;
}
.logout {
}

/*Task SIde */
.tasks {
  width: 45%;
  text-align: left;
  float:left;
  margin-left: 5%;
  margin-top: 0px;
  margin-bottom: 0px;
}

.tasks h2 {
  font-size: 40px;
  margin: 0px;
  margin-bottom: 10px;
}
.tasks h4 {
  font-size: 35px;
  display: contents;
}
.tasks h5 {
  display: block;
  float: right;
  font-size: 30px;
  margin: 0px;
}
.tasks h6 {
  font-size: 20px;
  display: contents;
  font-weight: 500;
  padding: 3px;
}
.tasks p {
  margin: 0px;
  font-size: 20px;
  display: block;
  white-space: pre-line;
}
.class {
}
.tasks a {
  text-align: right;
  text-decoration: none;
  color: white;
}
.tasks button {
  float: right;
  font-size: 25px;
  background-color: #585858;
  border: 0px;
  color: white;
  font-family: 'Lato', sans-serif;
  font-weight: bold;
  cursor: pointer;
  display: block;
  position: absolute;
  top: 10px;
  right: 10px;
  padding-left: 0px;
  margin-left: 5px;
}
.due {
  text-align: right;
}
.edit {
  float: left;
  font-style: italic;
}
.class {
  float: left;
  font-style: italic;
}
.bottomrow {
  margin-top: 2%;
}
.title {
  margin-right: 5%;
}
/*Create*/
.create {
  width: 39%;
  text-align: left;
  float: right;
  margin-right: 5%;
}
.create h2 {
  font-size: 40px;
  margin: 0px;
  margin-bottom: 10px;
}
/* Cards and Containers */
.card {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  width: 100%;
  border-radius: 10px;
  background-color: #585858;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.container {
  padding: 2px 16px;
  margin-bottom: 5%;
  position: relative;
}

/*Form*/
input[type=text], select {
  width: 100%;
  padding: 8px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 20px;
  background-color: lightgrey;
}

textarea{
  width: 100%;
  height: 125px;
  padding: 4px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
  background-color: lightgrey;
  resize: inherit  ;
}

button[type=submit] {
  width: 100%;
  background-color: #07deaf;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 25px;
  font-weight: 700;
}

input[type=submit]:hover {
  background-color: #45a049;
}
button[type=delete] {
  width: 100%;
  font-size: 14px;
  float: right;
  background-color: #ff424d;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 700;
}
/*Date Picker*/
.react-datetime-picker__inputGroup__hour {
  min-width: 20px;
  font-size: 20px;
  color: white;
  background-color: #585858;
  border: 0px;
  text-align: center;
}
.react-datetime-picker__inputGroup__hour::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-datetime-picker__inputGroup__minute {
  min-width: 20px;
  font-size: 20px;
  color: white;
  background-color: #585858;
  border: 0px;
}
.react-datetime-picker__inputGroup__minute::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-datetime-picker__inputGroup__year {
  min-width: 40px;
  font-size: 20px;
  color: white;
  background-color: #585858;
  border: 0px;
}
.react-datetime-picker__inputGroup__year::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-datetime-picker__inputGroup__month {
  min-width: 20px;
  font-size: 20px;
  color: white;
  background-color: #585858;
  border: 0px;
}
.react-datetime-picker__inputGroup__month::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-datetime-picker__inputGroup__day {
  min-width: 20px;
  font-size: 20px;
  color: white;
  background-color: #585858;
  border: 0px;
}
.react-datetime-picker__inputGroup__day::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-datetime-picker__inputGroup__amPm {
  min-width: 40px;
  max-width: 60px;
  font-size: 20px;
  color: white;
  background-color: #585858;
  border: 0px;
}
.react-datetime-picker__inputGroup__leadingZero {
  min-width: 40px;
  max-width: 60px;
  font-size: 20px;
  color: white;
  background-color: #585858;
  border: 0px;
}
.react-datetime-picker__button {
  display: none;
}
/*Calendar*/

.react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
}

.react-calendar__tile {
    max-width: 100%;
    text-align: center;
    padding: 0.75em 0.5em;
    background: none;
}

.react-calendar, .react-calendar *, .react-calendar *:before, .react-calendar *:after {
    box-sizing: border-box;
}
.react-calendar {
    width: 350px;
    max-width: 100%;
    background: white;
    border: 1px solid #a0a096;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
}

.react-calendar, .react-calendar *, .react-calendar *:before, .react-calendar *:after {
    box-sizing: border-box;
}

.react-calendar, .react-calendar *, .react-calendar *:before, .react-calendar *:after {
    box-sizing: border-box;
}

/*Mobile */
@media only screen and (max-width: 600px) {
  .tasks {
    width: 90%;
  }
  .tasks h4 {
    font-size: 30px;
  }
  .tasks p {
    font-size: 18px;
    font-family: inherit;
  }
  .create {
    width: 90%;
  }
  .create h4 {
    font-size: 30px;
  }
  .create p {
    font-size: 18px;
  }
  .react-datetime-picker__inputGroup__amPm {
    min-width: 60px;
    max-width: 70px;
  }
}
/* Landing Page */
.landing {
  background-color: #19758f;
  align-items: center;
}
.landing h1 {
  margin-bottom: 1px;
}
.landing h2 {
  margin: 1px;
  margin-bottom: 2%;
}
.landing .box {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  width: 30%;
  border-radius: 10px;
  background-color: #1af0cc;
  display: inline-block;
  margin-bottom: 2%;
  margin-top: 2%;
}
/*Doc*/
.doc {
  max-width: 75%;
  margin-left: auto;
  margin-right: auto;
}
.docList .tasks .docBut button {
  display: contents;
  float: left;
}
.docBut {
  margin-bottom: 4%;
  background-color: #07deaf;
  color: white;
  padding: 14px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 25px;
  font-weight: 700;
}
